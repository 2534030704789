import { React, useState } from "react";
import { Form, Row, Col, Button, ToastContainer, Toast, Spinner } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "../components/Autocomplete";
import Estado from "../components/Estado";
import TabelaEmpresa from "../components/TabelaEmpresa";
import BackendService from "../services/BackendService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import search from "../data/search.json";

function Adm() {

    const [state, setState] = useState(search);
    const [validated, setValidated] = useState(false);
    const [size, setSize] = useState(search.size);
    const [cep, setCep] = useState("");
    const [findData, setFindData] = useState(false);
    const [findMsg, setFindMsg] = useState("");
    const [ufSelected, setUfSelected] = useState(search.uf);
    const [cidadeSelected, setCidadeSelected] = useState(search.cidade);
    const [bairroSelected, setBairroSelected] = useState(search.bairro);
    const [razaoSocial, setRazaoSocial] = useState(search.razaoSocial);
    const [listCidades, setListCidades] = useState([]);
    const [listBairros, setListBairros] = useState([]);
    const [, setIsUfSelected] = useState(false);
    const [, setIsCidadeSelected] = useState(false);
    const [, setIsBairroSelected] = useState(false);
    const [isFind, setIsFind] = useState(false);
    const [isClean, setIsClean] = useState(false);
    const [lenTable, setLenTable] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [bgToast, setBgToast] = useState("danger");
    const [iconFill, setIconFill] = useState("triangle-exclamation");
    const [message, setMessage] = useState("Erro");
    const DANGER = "danger";
    const WARNING = "warning";
    const TRIANGLE = "triangle-exclamation";

    async function searchCep (value) {
        const cep = value.replace(/\D/g, "");
        if (cep !== "") {        
            const validacep = /^[0-9]{8}$/;
            if(validacep.test(cep)) {
                setShowToast(false);
                setFindMsg("Buscando CEP...");
                setFindData(true);
                await BackendService
                    .getCep(cep)
                    .then((response) => {
                        getMunicipios(response.data.uf);
                        getBairros(response.data.localidade, response.data.uf);
                        setFields(response.data.uf, response.data.localidade, response.data.bairro);
                        setFindData(false);
                        setFindMsg("");
                    })
                    .catch((error) => {
                        let msg = "";
                        error.response.data.validacoes.forEach(element => {
                            msg += element.mensagem + ".\n";
                        });
                        toastShowed(WARNING, TRIANGLE, msg);
                        console.log(error)
                    });
            }
            else{
                selectUf("");
                toastShowed(DANGER, TRIANGLE, "CEP inválido!");
            }
        }
        else {
            selectUf("");
        }
    }

    function setFields(uf, cidade, bairro) {
        setUfSelected(uf);
        setCidadeSelected(cidade);
        setBairroSelected(bairro);
        setIsUfSelected(true);
        setIsCidadeSelected(true);
        setIsBairroSelected(true);
        search.uf = uf;
        search.cidade = cidade;
        search.bairro = bairro;
        setState(search);
    }

    function selectUf(value) {
        if (value !== ufSelected) {
            setCep("");
            setIsUfSelected(value !== "" ? true : false);
            getMunicipios(value);
        }
        setUfSelected(value);
        search.uf = value;
        setState(search);
    }

    function selectCidade(value) {
        if (value !== cidadeSelected) {
            setCep("");
            setIsCidadeSelected(value !== "" ? true : false);
            getBairros(value, ufSelected);
        }
        setCidadeSelected(value);
        search.cidade = value;
        setState(search);
    }

    function selectBairro(value) {
        setIsClean(true);
        if (value !== bairroSelected) {
            setCep("");
            setIsBairroSelected(value !== "" ? true : false);
        }
        setBairroSelected(value);
        search.bairro = value;
        setState(search);
    }

    function selectRazaoSocial (value) {
        setRazaoSocial(value);
        search.razaoSocial = value;
        setState(search);
    }

    function selectSize(value) {
        setSize(value);
        search.size = value;
        setState(search);
    }

    function cleanCidade() {
        setCidadeSelected("");
        search.cidade = "";
        setIsCidadeSelected(false);
        setListCidades([]);
        cleanBairro();
    }

    function cleanBairro() {
        setBairroSelected("");
        search.bairro = "";
        setIsBairroSelected(false);
        setListBairros([]);
        setState(search);
        setIsClean(true);
    }

    async function getMunicipios(uf) {
        cleanCidade();
        if(uf !== "") {
            try {
                const response = await BackendService.getMunicipios(uf);
                setListCidades(response.data);
            } catch (error) {
                if (error.response.status === 404) {
                    toastShowed(WARNING, TRIANGLE, "Não existem cidades para o Estado selecionado!");
                    return false;
                }
                else {
                    let msg = "";
                    error.response.data.validacoes.forEach(element => {
                        msg += element.mensagem + ".\n";
                    });
                    toastShowed(WARNING, TRIANGLE, msg);
                    return console.log(error);
                }
            }
        }
    }

    async function getBairros(cidade, uf) {
        cleanBairro();
        if (cidade !== "" && uf !== "") {
            try {
                const response = await BackendService.getBairros(cidade, uf);
                setListBairros(response.data);
            } catch (error) {
                if (error.response.status === 404) {
                    toastShowed(WARNING, TRIANGLE, "Não existem bairros para a Cidade/Estado selecionado!");
                    return false;
                }
                else {
                    let msg = "";
                    error.response.data.validacoes.forEach(element => {
                        msg += element.mensagem + ".\n";
                    });
                    toastShowed(WARNING, TRIANGLE, msg);
                    return console.log(error);
                }
            }
        }
    }

    function sendSearch() {
        setShowToast(false);
        setValidated(true);
        if (state.uf !== "" && state.cidade !== "") {
            search.page = 0;
            setState(search)
            setIsClean(false);
            setIsFind(true);
            setFindMsg("Buscando empresas...");
            setFindData(true);
        }
        else {
            toastShowed(WARNING, TRIANGLE, "Favor preencher pelo menos Estado e Cidade");
        }
    }

    function resetForm() {
        selectUf("");
        setValidated(false);
        setShowToast(false);
    }

    function exportPdf() {
        if (!isClean){
            const today = new Date();
            const composeName = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2,0) + "-" + today.getDate().toString().padStart(2,0) + "_" + 
                            today.getHours().toString().padStart(2,0) + "-" + today.getMinutes().toString().padStart(2,0) + "-" + today.getSeconds().toString().padStart(2,0);
            const pdf = new jsPDF("l","pt","a4");
            pdf.autoTable({ html: "#conteudo" });
            pdf.save("Report_" + composeName + ".pdf");
        }
    }

    function tableReturned(value, len, msg) {
        value === "find" ? setIsFind(!isFind) : setIsClean(false);
        setFindData(false);
        setFindMsg("");
        setLenTable(len);
        if (len < 0) {
            toastShowed(WARNING, TRIANGLE, msg);
        }
    }

    function toastShowed(bg, icon, msg) {
        setShowToast(true);
        setBgToast(bg);
        setIconFill(icon);
        setMessage(msg);
    }


/*
    useEffect( () => {
        if (isUfSelected) {
            getMunicipios(ufSelected);
        }
    }, [isUfSelected, ufSelected]);

    useEffect( () => {
        if (isCidadeSelected) {
            getBairros(cidadeSelected, ufSelected);
        }
    }, [isCidadeSelected, cidadeSelected, ufSelected]);
*/
    return (
        <Row className="cincoacertos">
            <Col>
        { findData ? (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{width: "100%", height: "100%"}}>
                <div className="row">
                    <Spinner as="h3" style={{width: "3rem", height: "3rem"}} variant="light" role="status" /><br />
                </div>
                <div className="row">
                    <h4>{findMsg}</h4>
                </div>
            </div>
        ) : (
            <>
                <Row style={{height:"10vh"}}>
                    <Col />
                </Row>
                <Form validated={validated}>
                    <fieldset disabled={isFind}>
                        <Row className="mb-3">
                            <Col>
                                <h2>Busca de empresas</h2>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs md={2}>
                                <Form.FloatingLabel label="CEP" controlId="cep">
                                    <Form.Control name="cep" placeholder="CEP" as={InputMask} mask="99999-999" onBlur={(e) => searchCep(e.target.value)} value={cep} onChange={e => setCep(e.target.value)} />
                                </Form.FloatingLabel>
                            </Col>
                            <Col xs md={3}>
                                <Estado valueSent={ufSelected} ufReturned={(uf) => selectUf(uf)} />
                            </Col>
                            <Col xs md={4}>
                                <Autocomplete
                                    listValues={listCidades}
                                    label="Cidade"
                                    controlId="cidade"
                                    valueSent={cidadeSelected}
                                    isRequired={true}
                                    valueReturned={ (value) => selectCidade(value) } />
                            </Col>
                            <Col xs md={3} id="colBairro">
                            <Autocomplete
                                    listValues={listBairros}
                                    label="Bairro"
                                    controlId="bairro"
                                    valueSent={bairroSelected}
                                    isRequired={false}
                                    valueReturned={ (value) => selectBairro(value) } />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs md={10}>
                                <Form.FloatingLabel label="Razão Social" controlId="razaoSocial">
                                    <Form.Control type="text" name="razaoSocial" placeholder="Razão Social" value={razaoSocial} onChange={e => selectRazaoSocial(e.target.value)} />
                                </Form.FloatingLabel>
                            </Col>
                            <Col xs md={2}>
                                <Form.FloatingLabel label="Itens por página" controlId="size">
                                    <Form.Select name="size" value={size} onChange={e => selectSize(e.target.value)}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </Form.Select>
                                </Form.FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col style={{ textAlign: "left" }}>
                                <Button variant="primary" size="lg" id="buscar" name="buscar" onClick={sendSearch}>{isFind ? "Buscando..." : "Buscar"}</Button> &nbsp;
                                <Button variant="secondary" size="lg" id="limpar" name="limpar" type="button" onClick={resetForm}>Limpar</Button> &nbsp;
                                <Button variant="success" size="lg" id="gerarPdf" name="gerarPdf" onClick={exportPdf} disabled={lenTable<=0}>Gerar PDF</Button> &nbsp;
                            </Col>
                        </Row>
                    </fieldset>
                </Form>
                </>
            )}
                <Row className="mb-3">
                    <Col>
                        <TabelaEmpresa search={state} isFind={isFind} isClean={isClean} valueReturned={ (value, len, msg) => tableReturned(value, len, msg) } />
                    </Col>
                </Row>
                <ToastContainer position="bottom-center">
                    <Toast className={"text-bg-" + bgToast} bg={bgToast} show={showToast} onClose={() => setShowToast(false)}>
                        <div className="d-flex">
                            <Toast.Body>
                                <FontAwesomeIcon icon={iconFill} size="2xl" />
                                <span className="me-auto"> {message}</span>
                            </Toast.Body>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" aria-label="Close" onClick={() => setShowToast(false)}></button>
                        </div>
                    </Toast>
                </ToastContainer>
            </Col>
        </Row>
    );

}

export default Adm;