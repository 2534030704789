import { useEffect, useState } from "react";
import { useNavigate , useParams } from "react-router-dom";
import { Row, Col, Form, Button, Toast, ToastContainer } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../img/anhanguera/logo-1.png";
import polos from "../data/polos.json";
import data from "../data/formAluno.json";

export default function Anhanguera() {

    const [state, setState] = useState(data);
    const [validated, setValidated] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [mask, setMask] = useState("(99) 99999-9999");
    const [polo, setPolo] = useState({});
    const [textSend, setTextSend] = useState("Enviar dados");
    const [showToast, setShowToast] = useState(false);
    const [bgToast, setBgToast] = useState("danger");
    const [iconFill, setIconFill] = useState("triangle-exclamation");
    const [message, setMessage] = useState("Erro");
    const { idpolo } = useParams();
    const navigate = useNavigate();

    function handleSubmit (event) {
        setTextSend("Enviando...");
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            toastShowed("warning", "triangle-exclamation", "Faltam campos a serem preenchidos!");
            setIsSending(false);
            setTextSend("Enviar dados");
        }
        else {
            toastShowed("success", "circle-check", "Cadastro realizado com sucesso!");
            setIsSending(true);
            setTextSend("Dados enviados");
            event.preventDefault();
            event.stopPropagation();
        }        
    }

    function changeMaskPhone(event, mask) {
        if (event.target.value.replace("_", "").length === 14)
            setMask(mask);
    }

    function toastShowed(bg, icon, msg) {
        setShowToast(true);
        setBgToast(bg);
        setIconFill(icon);
        setMessage(msg);
    }

    useEffect(() => {
        function showPole(id) {
            if (id === undefined) {
                id = "vazio";
            }
            if (polos.map(polo => polo.id).includes(idpolo)) {
                setPolo(polos.find(polo => polo.id === idpolo));
            }
            else {
                console.log("Não achou polo!");
                toastShowed("warning", "triangle-exclamation", "Polo não localizado! Redirecionado para outro polo");
                navigate("/anhanguera/" + polos.find((polo, index) => index === 0).id);
            }
        }
        showPole(idpolo);
    }, [idpolo, navigate]);

    return (
        <Row className="body-anhanguera">
            <Col xs>
                <Row>
                    <Col xs sm={2}>
                        <img src={logo} className="img-fluid" alt="Logo Anhanguera" />
                    </Col>
                    <Col xs sm={10}>
                        <h1 className="display-4">Faculdade Anhanguera - Polo <span id="nomepolo">{polo.nomepolo}</span></h1>
                        <span id="endpolo">{polo.endpolo}</span><br />
                        <FontAwesomeIcon icon={["fab", "whatsapp"]} style={{"color": "#00E676", "fontWeight" : "bold"}} /> <span id="fonepolo">{polo.fonepolo}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col xs className="justify-content-end">
                        <strong className="float-start">Preencha seus dados abaixo para concorrer ao sorteio de um brinde</strong>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        &nbsp;
                    </Col>
                </Row>
                <Form validated={validated} role="form" name="formalunos" id="formalunos" autoComplete="off" method="POST" action="./" noValidate onSubmit={handleSubmit}>
                    <input type="hidden" name="idpolo" id="idpolo" value={idpolo} />
                    <fieldset disabled={isSending}>
                        <legend className="text-start">Dados</legend>
                        <Row className="mb-2">
                            <Col xs sm={8} md={9} xl={10}>
                                <Form.Floating>
                                    <Form.Control id="nome" name="nome" placeholder="Qual o seu nome completo?" value={state.nome} onChange={ (e) => setState( (prev) => ({...prev, nome: e.target.value}))} required />
                                    <label htmlFor="nome">Qual o seu nome completo? <FontAwesomeIcon icon={["fas", "signature"]} /> *</label>
                                </Form.Floating>
                            </Col>
                            <Col xs sm={4} md={3} xl={2}>
                                <Form.FloatingLabel label="Qual a sua idade? *" controlId="idade">
                                    <Form.Control name="nome" placeholder="Qual a sua idade? *" type="number" min={0} value={state.idade} onChange={ (e) => setState( (prev) => ({...prev, idade: e.target.value}))} required />
                                </Form.FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={6} sm={4} className="mb-2">
                                <Form.Floating>
                                    <Form.Select id="serie" name="serie" value={state.serie} onChange={ (e) => setState( (prev) => ({...prev, serie: e.target.value}))} required>
                                        <option value="">Selecione uma opção</option>
                                        <option value="6º EF">6º Ensino Fundamental</option>
                                        <option value="7º EF">7º Ensino Fundamental</option>
                                        <option value="8º EF">8º Ensino Fundamental</option>
                                        <option value="9º EF">9º Ensino Fundamental</option>
                                        <option value="1º EM">1º Ensino Médio</option>
                                        <option value="2º EM">2º Ensino Médio</option>
                                        <option value="3º EM">3º Ensino Médio</option>
                                    </Form.Select>
                                    <label htmlFor="serie">Série/Turma <FontAwesomeIcon icon={["fas", "people-group"]} /> *</label>
                                </Form.Floating>
                            </Col>
                            <Col xs={6} sm={4}>
                                <Form.Floating>
                                    <Form.Control id="cpf" name="cpf" placeholder="CPF" as={InputMask} mask="999.999.999-99" value={state.cpf} onChange={ (e) => setState( (prev) => ({...prev, cpf: e.target.value}))} />
                                    <label htmlFor="cpf">CPF <FontAwesomeIcon icon={["fas", "id-card"]} /></label>
                                </Form.Floating>
                            </Col>
                            <Col xs={6} sm={4}>
                                <Form.Floating>
                                    <Form.Control id="email" name="email" placeholder="E-mail" type="email" value={state.email} onChange={ (e) => setState( (prev) => ({...prev, email: e.target.value}))} />
                                    <label htmlFor="email">E-mail <FontAwesomeIcon icon={["fas", "at"]} /></label>
                                </Form.Floating>
                            </Col>
                            <Col xs={6} sm={4} className="mb-2">
                                <Form.Floating>
                                    <Form.Control id="celular" name="celular" as={InputMask} mask={mask}
                                        placeholder="Telefone/Celular/WhatsApp *" value={state.celular}
                                        onChange={ (e) => setState( (prev) => ({...prev, celular: e.target.value}))} required
                                        onBlur={ (e) => changeMaskPhone(e, "(99) 9999-9999") }
                                        onFocus={ (e) => changeMaskPhone(e, "(99) 99999-9999") }
                                        pattern="(\([0-9]{2}\))\s([9]{1})?([0-9]{4})-([0-9]{4})" />
                                    <label htmlFor="celular">Telefone <FontAwesomeIcon icon={["fas", "phone-flip"]} />/Celular <FontAwesomeIcon icon={["fas", "mobile-screen"]} />/WhatsApp <FontAwesomeIcon icon={["fab", "whatsapp"]} /> *</label>
                                </Form.Floating>
                            </Col>
                            <Col xs={6} sm={4}>
                                <Form.Floating>
                                    <Form.Control id="instagram" name="instagram" placeholder="Instagram" value={state.instagram} onChange={ (e) => setState( (prev) => ({...prev, instagram: e.target.value}))} />
                                    <label htmlFor="instagram">Instagram <FontAwesomeIcon icon={["fab", "instagram"]} /></label>
                                </Form.Floating>
                            </Col>
                            <Col xs={6} sm={4}>
                                <Form.Floating>
                                    <Form.Control id="facebook" name="facebook" placeholder="Facebook" value={state.facebook} onChange={ (e) => setState( (prev) => ({...prev, facebook: e.target.value}))} />
                                    <label>Facebook <FontAwesomeIcon icon={["fab", "facebook"]} /></label>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row className="mb-2 justify-content-end">
                            <Col xs>
                                <Button type="submit" variant="primary" id="enviar" name="enviar" className="float-end">{textSend}</Button>
                            </Col>
                        </Row>
                    </fieldset>
                </Form>
                <ToastContainer position="bottom-center">
                    <Toast className="text-bg-success" bg={bgToast} show={showToast} onClose={() => setShowToast(false)}>
                        <div className="d-flex">
                            <Toast.Body>
                                <FontAwesomeIcon icon={iconFill} size="2xl" />
                                <span className="me-auto"> {message}</span>
                            </Toast.Body>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" aria-label="Close" onClick={() => setShowToast(false)}></button>
                        </div>
                    </Toast>
                </ToastContainer>
            </Col>
        </Row>
    );

}
