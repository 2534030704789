import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

function DetalheEmpresa (props) {
    const [state, setState] = useState(props.empresa);

    function formatDate(date) {
        if (date) {
            let newDate = new Date(date);
            let newDay = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
            let newMonth = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
            let dateFormatted = newDay + "/" + newMonth + "/" + newDate.getFullYear();
            return dateFormatted;
        }
        return "-"
    }

    useEffect( () => {
        if (props.empresa) {
            setState(props.empresa);
        }
    }, [props.empresa]);

    return (
        <>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Razão social:</strong>
                </Col>
                <Col xs={9}>
                    {state.razaoSocial}
                </Col>
            </Row>
            <Row className="mb-3">
            <Col xs={3}>
                    <strong>Nome fantasia:</strong>
                </Col>
                <Col xs={9}>
                    {state.nomeFantasia ? state.nomeFantasia : "Não tem"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Endereço:</strong>
                </Col>
                <Col xs={9}>
                    {state.tipoLogradouro} {state.logradouro}, {state.numeroLogradouro}, {state.complemento ? state.complemento + ", " : ""}{state.bairro} -&nbsp;
                    {state.nomeMunicipio} - {state.uf} - CEP: {state.cep}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Data início:</strong>
                </Col>
                <Col xs={3}>
                    {formatDate(state.dataInicio)}
                </Col>
                <Col xs={3}>
                    <strong>Matriz/Filial:</strong>
                </Col>
                <Col xs={3}>
                    {state.matriz ? "Matriz" : "Filial"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Situação cadastral:</strong>
                </Col>
                <Col xs={3}>
                    {state.situacao}
                </Col>
                <Col xs={3}>
                <strong>Data situação:</strong>
                </Col>
                <Col xs={3}>
                    {formatDate(state.dataSituacao)}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Motivo situação:</strong>
                </Col>
                <Col xs={9}>
                    {state.motivoSituacao === "00" ? "Não tem" : state.motivoSitucao}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Simples nacional:</strong>
                </Col>
                <Col xs={3}>
                    {state.simples === "5" || state.simples === "7" ? "Sim" : "Não"}
                </Col>
                <Col xs={3}>
                    <strong>Data opção:</strong>
                </Col>
                <Col xs={3}>
                    {state.dataOpcaoSimples ? formatDate(state.dataOpcaoSimples) : formatDate(state.dataExclusaoSimples)}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Situação especial:</strong>
                </Col>
                <Col xs={3}>
                    {state.situacaoEspecial ? state.situacaoEspecial : "Não tem"}
                </Col>
                <Col xs={3}>
                <strong>Data situação:</strong>
                </Col>
                <Col xs={3}>
                    {state.dataSituacaoEspecial ? formatDate(state.dataSituacaoEspecial) : "-"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Porte empresa:</strong>
                </Col>
                <Col xs={3}>
                    {state.porte}
                </Col>
                <Col xs={3}>
                <strong>MEI:</strong>
                </Col>
                <Col xs={3}>
                    {state.mei === "S" ? "Sim" : "Não"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>Telefone 1:</strong>
                </Col>
                <Col xs={3}>
                    {state.telefone1 ? state.telefone1 : "Não tem"}
                </Col>
                <Col xs={3}>
                <strong>Telefone 2:</strong>
                </Col>
                <Col xs={3}>
                    {state.telefone2 ? state.telefone2 : "Não tem"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>E-mail:</strong>
                </Col>
                <Col xs={9}>
                    {state.email ? state.email : "Não tem"}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={3}>
                    <strong>CNAE principal:</strong>
                </Col>
                <Col xs={9}>
                    <strong>Descrição</strong>
                </Col>
                <Col xs={3}>
                    {state.cnae}
                </Col>
                <Col xs={9}>
                    {state.descricaoCnae}
                </Col>
            </Row>
            <Row>
                <Col xs={3}>
                    <strong>CNAE(s) secundário(s)</strong>
                </Col>
                <Col xs={9}>
                    <strong>Descrição</strong>
                </Col>
            </Row>
                {
                    state.cnaeSecundarios && state.cnaeSecundarios.length > 0 ? (
                    state.cnaeSecundarios.map((item, index) => {
                        return (
                            <Row key={index}>
                                <Col xs={3}>
                                    {item.id.cnaeSecundario}
                                </Col>
                                <Col xs={9}>
                                    {item.nomeCnae}
                                </Col>
                            </Row>
                        )
                    })
                    ) : (
                        <Row>
                            <Col xs={12}>
                                Nenhum CNAE secundário
                            </Col>
                        </Row>
                    )
                }
        </>
    );
}

export default DetalheEmpresa;