import axios from "axios";
import decode from "jwt-decode";

const http = axios.create({
    baseURL: "/api/empresas",
    headers: {
        "Content-type": "application/json"
    }
});

const dataUser = {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD
}

//http.defaults.timeout = 2500;

getToken();

http.interceptors.request.use(async (config) => {
    if (!config.url.endsWith("signin")) {
        if(tokenIsValid(http.defaults.headers.common["Authorization"])) {
            console.log("Token menor que data atual");
            await getToken();
        }
        //console.log(http.defaults.headers.common["Authorization"]);
        /*
        await http.post("/auth/signin", dataUser)
            .then(resp => {
                http.defaults.headers.common["Authorization"] = `Bearer ${resp.data.token}`;
                console.log(jwt(resp.data.token).exp);
                console.log(Math.floor(new Date() / 1000));
            })
            .catch(error => console.log(error));
            */
    }
    return config;
}, (error) => {
    // I cand handle a request with errors here
    return Promise.reject(error);
});
/*
const searchEmpresas = (data, token) => {
    return http.post("/search", data, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }});
};
*/
const searchEmpresas = (data) => {
    return http.post("/search", data);
};

const getEmpresa = (cnpj) => {
    return http.get("/search/" + cnpj);
};
/*
const getToken = () => {
    return http.post("/auth/signin", dataUser);
}
*/
async function getToken () {
    await http.post("/auth/signin", dataUser)
        .then(resp => {
            http.defaults.headers.common["Authorization"] = `Bearer ${resp.data.token}`;
        })
        .catch(error => console.log(error));
}

function tokenIsValid(token) {
    const exp = decode(token);
    const now = Math.floor(new Date() / 1000);

    if (exp <= now) {
        return true
    }
    else {
        return false;
    }
}

const EmpresasService = {
    searchEmpresas,
    getEmpresa
};
  
export default EmpresasService;
