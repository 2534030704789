import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NoPage() {
    return (
        <Row className="cincoacertos">
            <Col>
                <Row style={{height:"10vh"}}>
                    <Col />
                </Row>
                <Row style={{height:"15vh"}}>
                    <Col>
                        <h1>Página não localizada!</h1>
                        <Link to="/">Ir para Home <FontAwesomeIcon icon="house" /></Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}

export default NoPage;