import { useState, useEffect } from "react";
import PropTypes from "prop-types";

function Fader ({ text }) {
    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-in',
    });

    const [intervalo, setIntervalo] = useState(4500);

    useEffect(() => {
        const timeout = setInterval(() => {
            if (fadeProp.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                });
                setIntervalo(500);
            } else {
                setFadeProp({
                    fade: 'fade-in'
                });
                setIntervalo(4500);
            }
        }, intervalo);

        return () => clearInterval(timeout);
    }, [fadeProp, intervalo]);

    return (
        <h5 data-testid="fader" className={fadeProp.fade}>{text}</h5>
    )
}

Fader.defaultProps = {
    text: ''
}

Fader.propTypes = {
    text: PropTypes.string
}

export default Fader