import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import BackendService from "../services/BackendService";

function Estados ( { valueSent, ufReturned } ) {

    const [listUfs, setListUfs] = useState([]);

    const [, setUfSelected] = useState(valueSent);

    useEffect( () => {
        BackendService
            .getEstados()
            .then((response) => {
                setListUfs(response.data);
            })
            .catch((error) => console.log(error));
    }, []);

    function selectUf(uf) {
        setUfSelected(uf);
        ufReturned(uf);
        valueSent = uf;
    }

    return (
        <Form.FloatingLabel label="Estado" controlId="uf">
            <Form.Select name="uf" value={valueSent} onChange={e => selectUf(e.target.value)} required>
                <option value="">Selecione um estado</option>
                {
                    listUfs.map(uf =>
                        <option value={uf.uf} key={uf.uf}>{uf.nome}</option>
                    )
                }
            </Form.Select>
        </Form.FloatingLabel>
    );
}

export default Estados;
