import React from "react";
import { Table, Modal, Button } from "react-bootstrap";
import EmpresasService from "../services/EmpresasService";
import Paginacao from "./Paginacao";
import Tbody from "./Tbody";
import DetalheEmpresa from "./DetalheEmpresa";
import tabelaEmpresa from "../data/stateTabelaEmpresa.json";

export default class TabelaEmpresa extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.getToken = this.getToken.bind(this);
        this.getPage = this.getPage.bind(this);
    }

    state = tabelaEmpresa;

    componentDidMount() {
        //this.getToken();
        this.setState({
            data: [],
            limit: this.props.search.size,
            activePage: this.props.search.page + 1,
            totalElements: 0,
            totalPages: 0,
            search: this.props.search,
            isLoading: false,
            modalShow: false,
            empresa: {}
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFind !== this.props.isFind) {
            //this.getToken();
            this.setState((prev) => ({
                ...prev,
                limit: this.props.search.size,
                activePage: this.props.search.page + 1,
                search: this.props.search
            }));
            if (this.props.isFind) {
                this.setState((prev) => ({
                    ...prev,
                    data: [],
                    limit: this.props.search.size,
                    activePage: this.props.search.page + 1,
                    totalElements: 0,
                    totalPages: 0
                }));
                this.getData(this.state.search);
            }            
        }
        if (prevProps.isClean !== this.props.isClean) {
            this.setState((prev) => ({
                ...prev,
                data: [],
                limit: this.props.search.size,
                activePage: this.props.search.page + 1,
                search: this.props.search,
                isLoading: false
            }));
            if (this.props.isClean) {
                this.setState((prev) => ({
                    ...prev,
                    data: [],
                    search: {}
                }));
                this.props.valueReturned("clean", 0, "");
            }
        }
    }

    async getData(data) {
        if (this.state.token !== "ERR_NETWORK") {
            this.setState((prev) => ({
                ...prev,
                isLoading: true
            }));
            try {
                const response = await EmpresasService.searchEmpresas(data);
                this.setState((prev) => ({
                    ...prev,
                    data: response.data.content,
                    totalElements: response.data.totalElements,
                    totalPages: response.data.totalPages,
                    isLoading: false
                }));
                this.props.valueReturned("find", response.data.content.length, "");
            } catch (error) {
                this.setState((prev) => ({
                    ...prev,
                    isLoading: false
                }));
                let msg = "";
                if(error.response.status === 504) {
                    msg += "A busca realizada está demorando muito!\n";
                    msg += "Favor refazer a busca adicionando mais campos.";
                }
                else {
                    if (error.response.data && error.response.data.validacoes) {
                        error.response.data.validacoes.forEach(element => {
                            msg += element.mensagem + ".\n";
                        });
                    }
                    else {
                        msg += "Ocorreu um erro no processamento.\n";
                        msg += "Favor tentar novamente em alguns instantes.";
                    }
                }
                this.props.valueReturned("find", -1, msg);
                return console.log(error);
            }
        }
        else {
            this.props.valueReturned("find", -1, "Não foi possível obter token para realizar a busca!");
        }
    }

    async getToken() {        
        try {
            const response = await EmpresasService.getToken();
            this.setState((prev) => ({
                ...prev,
                token: response.data.token
            }));
        } catch (error) {
            this.setState((prev) => ({
                ...prev,
                token: error.code
            }));
            return console.log(error);
        }
    }

    getPage(page) {
        let newSearch = this.state.search;
        newSearch.page = page - 1;
        if (page !== this.state.activePage) {
            this.setState((prev) => ({
                ...prev,
                activePage: page,
                search: newSearch
            }));
            //this.props.valueReturned("find", this.state.data.length, "");
            this.getData(newSearch);
        }
    }

    setModal(show, empresa) {
        this.setState((prev) => ({
            ...prev,
            modalShow: show,
            empresa: empresa
        }));
    }

    render() {
        
        return (
            <>
                <Table id="conteudo" striped hover variant="dark" style={{ textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th style={ {width: "9%"} }>CNPJ</th>
                            <th style={ {width: "5%"} }>Matriz?</th>
                            <th style={ {width: "22%"} }>Razão Social</th>
                            <th style={ {width: "20%"} }>Nome Fantasia</th>
                            <th style={ {width: "14%"} }>Bairro</th>
                            <th style={ {width: "11%"} }>Cidade - Estado</th>
                            <th style={ {width: "10%"} }>Porte Empresa</th>
                            <th style={ {width: "9%"} }>Situação Cadastral</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Tbody data={this.state.data} isLoading={this.state.isLoading} valueReturned={(modalShow, empresa) => this.setModal(modalShow, empresa)} />
                    </tbody>
                </Table>
                <Paginacao activePage={this.state.activePage} totalElements={this.state.data.length} totalPages={this.state.totalPages} valueReturned={(page) => this.getPage(page)} />
                <Modal show={this.state.modalShow} onHide={() => this.setState((prev) => ({...prev,modalShow: false}))} backdrop="static" keyboard={false} size="xl" dialogClassName="modal-dialog-scrollable" aria-labelledby="title-center" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="title-center">
                            CNPJ Empresa: <small>{this.state.empresa.cnpj}</small>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DetalheEmpresa empresa={this.state.empresa} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState((prev) => ({...prev,modalShow: false}))}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}