import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./pages/Menu";
import Home from "./pages/Home";
import Adm from "./pages/Adm";
import NoPage from "./pages/NoPage";
import Anhanguera from "./pages/Anhanguera";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fontawesome";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Menu />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="adm" element={<Adm />} />
          <Route path="anhanguera/:idpolo?" element={<Anhanguera />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
