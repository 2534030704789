import React from "react";
import { Pagination } from "react-bootstrap";
import statePaginacao from "../data/statePaginacao.json";

export default class Paginacao extends React.Component {

    constructor(props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    state = statePaginacao;

    componentDidMount() {
        this.setState({
            activePage: this.props.activePage,
            totalElements: this.props.totalElements,
            totalPages: this.props.totalPages
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activePage !== this.props.activePage ||
            prevProps.totalElements !== this.props.totalElements ||
            prevProps.totalPages !== this.props.totalPages) {
            this.setState({
                activePage: this.props.activePage,
                totalElements: this.props.totalElements,
                totalPages: this.props.totalPages
            })
        }
    }

    handlePageChange(page) {
        this.setState((prev) => ({
            ...prev,
            activePage: page
        }));
        this.props.valueReturned(page)
    }

    render () {
        let itens = [];
        //const totalItens = 11;
        const actualValue = this.state.activePage;
        let initialValue = actualValue <= 5 ? 1 : actualValue - 5;
        let finalValue = actualValue < (this.state.totalPages - 5) ? (actualValue + 5) : actualValue ;
        if (initialValue > 1) {
            itens.push(1);
            if (initialValue > 2)
                itens.push("...");
        }
        for (let i = initialValue; i <= finalValue; i++) {
            itens.push(i);
        }
        if (finalValue < this.state.totalPages) {
            if (finalValue < this.state.totalPages - 1)
                itens.push("...");
            itens.push(this.state.totalPages);
        }
        return (
            <Pagination style={ this.state.totalElements > 0 ? {display: "flex"} : {display: "none"} }>
                <Pagination.First disabled={this.state.activePage === 1} onClick={() => this.handlePageChange(1)} />
                <Pagination.Prev disabled={this.state.activePage === 1} onClick={() => this.handlePageChange(this.state.activePage - 1)} />
                {
                    itens.map((item, index) => {
                        return (
                            <Pagination.Item
                                key={index}
                                active={item === this.state.activePage}
                                disabled={item === "..."}
                                onClick={() => this.handlePageChange(item)}>{item}</Pagination.Item>
                        )
                    })
                }
                <Pagination.Next disabled={this.state.activePage === this.state.totalPages} onClick={() => this.handlePageChange(this.state.activePage + 1)} />
                <Pagination.Last disabled={this.state.activePage === this.state.totalPages} onClick={() => this.handlePageChange(this.state.totalPages)} />
            </Pagination>
        )
    }
}