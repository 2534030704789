import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Fader from "../components/Fader";
import fundo from "../img/hands_office.jpg";

function Home() {
    const intervalo = 5000;
    const [count, setCount] = useState(0);

    const frases = [
        "O que seu negócio precisa, mais perto do que você imagina",
        "Claro que o seu negócio tem jeito! Procure a 5acertos",
        "Pare de errar e comece a acertar",
        "Está tentando entender por quê não consegue vender?",
        "Seu maior concorrente pode estar bem ao seu lado"
    ];

    useEffect(() => {
        const id = setTimeout(() => {
            if(count === frases.length - 1)
                setCount(0)
            else
                setCount(count + 1);
        }, intervalo);
        return () => clearInterval(id);
    });

    return (
        <Row className="cincoacertos">
            <Col>
                <Row style={{height:"10vh"}}>
                    <Col />
                </Row>
                <img src={fundo} alt="fundo" className="bg-image" />
                <Row style={{height:"15vh"}}>
                    <Col>
                        <h1>5acertos</h1>
                    </Col>
                </Row>
                <Row style={{height:"50vh"}}>
                    <Col>
                        <h4>
                            Aguarde por uma nova experiência na busca por produtos
                            e serviços em sua região!!!
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Fader text={frases[count]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Home;
