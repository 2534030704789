import React from "react";

export default class Tbody extends React.Component {

    state = {
        data: [],
        isLoading: false
    }

    componentDidMount() {
        this.setState( {
            data: [],
            isLoading: false
        })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.isLoading !== this.props.isLoading) {
            this.setState( {
                data: this.props.data,
                isLoading: this.props.isLoading
            });
        }
        if (prevProps.data !== this.props.data) {
            this.setState( {
                data: this.props.data
            });
        }
    }

    render() {
        return (
            <>
            {
                !this.state.isLoading ? (
                this.state.data.map((item, index) => {
                    return (
                        <tr key={index} role="button" onClick={() => this.props.valueReturned(true, item)}>
                            <td>{item.cnpj}</td>
                            <td>{item.matriz ? "Sim" : "Não"}</td>
                            <td>{item.razaoSocial}</td>
                            <td>{item.nomeFantasia}</td>
                            <td>{item.bairro}</td>
                            <td>{item.nomeMunicipio + " - " + item.uf}</td>
                            <td>{item.porte}</td>
                            <td>{item.situacao}</td>
                        </tr>
                    )
                })
                ) : (
                <tr style={{ display: "table-row" }}>
                    <td colSpan={10} style={ {display: "table-cell"} }>
                        <div className="spinner-container">
                            <div className="loading-spinner" />
                        </div>
                    </td>
                </tr>
                )
            }
            </>
        )
    }
}