import React from "react";
import { Form, ListGroup, Spinner } from "react-bootstrap";
import stateAutocomplete from "../data/stateAutocomplete.json";

export default class Autocomplete extends React.Component {

    constructor(props) {
        super(props);
        this.removeDiacriticAndCase = this.removeDiacriticAndCase.bind(this);
        this.filterResults = this.filterResults.bind(this);
        this.handleInputChange = this.inputChange.bind(this);
        this.onNameSelected = this.onNameSelected.bind(this);
    }

    state = stateAutocomplete;

    componentDidMount() {
        this.setState({
            value: this.props.valueSent,
            list: this.props.listValues,
            label: this.props.label,
            controlId: this.props.controlId,
            results: [],
            isLoading: false,
            isValueSelected: false,
            isRequired: this.props.isRequired
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listValues !== this.props.listValues) {
            this.setState({
                value: this.props.valueSent,
                list: this.props.listValues,
                label: this.props.label,
                controlId: this.props.controlId,
                isValueSelected: true,
                isRequired: this.props.isRequired
            });
        }
    }

    removeDiacriticAndCase (value) {
        return value.normalize("NFD").replace(/[^a-zA-Z\s]/g, "").toLowerCase();
    }

    filterResults (query) {
        return new Promise((res, rej) => {
            setTimeout(() => {
                const searchResults = this.state.list.sort().filter((item) => this.removeDiacriticAndCase(item.nome).includes(this.removeDiacriticAndCase(query)));
                res(searchResults);
            }, 500);
        });
    };

    inputChange (valueSelected) {
        this.setState({
            value: valueSelected,
            isValueSelected: false,
            results: []
        });
        if (valueSelected.length > 1) {
            this.setState({isLoading: true});
            this.filterResults(valueSelected)
                .then((res) => {
                    this.setState({results: res, isLoading: false});
                })
                .catch(() => {
                    this.setState({isLoading: false});
                });
        }
    };

    onNameSelected (selectedValue) {
        this.setState({
                value: selectedValue,
                isValueSelected: true,
                results: []
            });
        this.props.valueReturned(selectedValue);
    }

    render() {
        return (
            <Form.FloatingLabel label={this.state.label} controlId={this.state.controlId} className="typeahead-form-group">
                <Form.Control 
                    type="text" 
                    name={this.state.controlId} 
                    placeholder={this.state.label} 
                    value={this.state.value} 
                    onChange={e => this.inputChange(e.target.value)} 
                    required={this.state.isRequired} />
                <ListGroup className="typeahead-list-group">
                    {!this.state.isValueSelected &&
                        this.state.results.length > 0 &&
                        this.state.results.map((result) => (
                            <ListGroup.Item
                                key={result.id}
                                className="typeahead-list-group-item"
                                onClick={() => this.onNameSelected(result.nome)}
                            >
                                {result.nome}
                            </ListGroup.Item>
                        ))
                    }
                    {!this.state.results.length && this.state.isLoading && (
                        <div className="typeahead-spinner-container">
                            <Spinner animation="border" />
                        </div>
                    )}
                </ListGroup>
            </Form.FloatingLabel>
        )
    }
}
