import axios from "axios";

const http = axios.create({
    baseURL: "/api/profdinho",
    headers: {
        "Content-type": "application/json"
    }
});

function getEstados () {
    return http.get(`/enderecos/estados`);
};

function getMunicipios (uf) {
    return http.get(`/enderecos/municipios/${uf}`);
}

function getBairros (municipio, uf) {
    return http.get(`/enderecos/bairros/${municipio}/${uf}`);
}

function getCep (cep) {
    return http.get(`/enderecos/cep/${cep}`);
}

const BackendService = {
    getEstados,
    getMunicipios,
    getBairros,
    getCep
};
  
export default BackendService;
