import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/App.css";

function Menu() {
    return (
        <Container fluid>
            <Outlet />
        </Container>
    );
}

export default Menu;
